import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { catchError, map, tap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import {
  UserLoginData,
  UserRegisterData,
  TokenResponse,
  UserInvitationData,
} from '@shared/interfaces/auth.interface';
import { TOKEN_KEY } from '@shared/interceptors';
import { of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthService {
  userLogoutEvent = new EventEmitter<string>();

  private host: string = environment.restAuthHost;
  private apiHost: string = environment.apiHost;

  constructor(private http: HttpClient) {}

  login(loginData: UserLoginData) {
    return this.http.post<TokenResponse | null>(`${this.host}login/`, loginData).pipe(
      map(response => {
        if (response != null) {
          this.setToken(response['key']);
        }

        return undefined;
      })
    );
  }

  /**
   * If account is successfully created, user token is saved to local storage
   */
  register(registerData: UserRegisterData) {
    return this.http.post<{ key: string }>(`${this.host}registration/`, registerData).pipe(
      map(res => {
        if (res.key) {
          this.setToken(res.key);
        }

        return undefined;
      })
    );
  }

  invitation(invitationData: UserInvitationData) {
    return this.http.post<{ key: string }>(`${this.apiHost}/users/invitation/`, invitationData);
  }

  logout() {
    return this.http.post(`${this.host}logout/`, {}).pipe(
      catchError(() => {
        return of(undefined);
      }),
      tap(() => localStorage.removeItem(TOKEN_KEY))
    );
  }

  isAuthenticated() {
    return !!localStorage.getItem(TOKEN_KEY);
  }

  resetPassword(payload: { email: string }) {
    return this.http.post<{ detail: string }>(`${this.host}password/reset/`, payload);
  }

  confirmReset(newPasswordData: {
    uid: string;
    token: string;
    new_password1: string;
    new_password2: string;
  }) {
    return this.http.post(`${this.host}password/reset/confirm/`, newPasswordData);
  }

  setToken(token: string) {
    localStorage.setItem(TOKEN_KEY, token);
  }
}
