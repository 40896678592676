import { HttpRequest, HttpHandlerFn, HttpEvent, HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError, Observable } from 'rxjs';
import { inject } from '@angular/core';
import { Router } from '@angular/router';

export const TOKEN_KEY = 'player_token';

export function authTokenInterceptor(
  req: HttpRequest<unknown>,
  next: HttpHandlerFn
): Observable<HttpEvent<unknown>> {
  const authToken = localStorage.getItem(TOKEN_KEY);

  if (authToken && req.url.startsWith(environment.host)) {
    const authReq = req.clone({
      setHeaders: {
        Authorization: `Token ${authToken}`,
      },
    });
    return next(authReq);
  }
  return next(req);
}

const INVALID_TOKEN_ERROR = 'Invalid token.'

export function authTokenErrorInterceptor(
  req: HttpRequest<unknown>,
  next: HttpHandlerFn
): Observable<HttpEvent<unknown>> {
  const router = inject(Router);

  return next(req).pipe(
    catchError((errorResponse: HttpErrorResponse) => {
      if (errorResponse.status === HttpStatusCode.Unauthorized && errorResponse.error?.detail === INVALID_TOKEN_ERROR) {
        localStorage.removeItem(TOKEN_KEY);
        router.navigate(['/account/signin']);
      }

      throw errorResponse;
    })
  )
}
